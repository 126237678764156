@import "../custom";

.omis-navbar {
	background-color: $COLOR_NAVBAR !important;
	color: $COLOR_BLACK_100 !important;
	display: inline-block !important;
	vertical-align: top !important;
	width: 15rem !important;
	height: 100vh !important;
	min-height: 100vh !important;
	overflow: hidden !important;
	position: fixed !important;
	.navbar-toggler button {
		margin-left: 13rem;
	}
	&.closed {
		width: 6rem !important;
		.navbar-toggler button {
			margin-left: 4rem !important;
		}
	}
}

.navbar-toggler button {
	margin-top: 2.3em !important;
	position: fixed !important;
	display: flex !important;
	z-index: 100000 !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	padding: 8px !important;
	gap: 8px !important;
	border: 0px !important;
	width: 30px !important;
	height: 30px !important;
	background: #FFFDFB !important;
	box-shadow: 0px 4px 12px rgba(103, 122, 142, 0.24) !important;
	border-radius: 24px !important;
}

.omis-navbar .nav-item {
	margin-bottom: 15px;
}

.omis-navbar ul {
	padding-left: 0px;
	margin-top: 6em;
  }

.omis-navbar .nav-item a svg {
	margin-left: 25px;
	margin-right: 8px;
	margin-bottom: 4px;
}

.omis-navbar .nav-item a {
	font-family: $FONTFAMILY;
	font-style: normal;
	font-weight: $FONT_WEIGHT_MEDIUM;
	font-size: $FONT_SIZE_MEDIUM;
	line-height: 40px;
	letter-spacing: 0.0025em;
	border-left: solid 5px $COLOR_NAVBAR;
	&:hover {
		color: $PRIMARY_ORANGE;
		background: #eff4ff10;
		border-left: solid 5px $PRIMARY_ORANGE;
	}
	&.active {
		color: $PRIMARY_ORANGE;
		background: #eff4ff10;
		border-left: solid 5px $PRIMARY_ORANGE;
	}
}

a.navbar-brand {
	white-space: normal;
	text-align: center;
	word-break: break-all;
}

.navbar-brand {
	margin-top: 0.95em;
	left: 0.8em !important;

	position: absolute;
	width: 68px;
	height: 38px;
	left: 40px;
	top: 0px;

	font-family: 'Urbanist';
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 38px;
	letter-spacing: 0.01em;

	color: $COLOR_WHITE;
	&:hover {
		color: $COLOR_WHITE;
	}
}



.omis-navbar-dropdown .btn-primary {
	background-color: transparent !important;
	border-color: transparent !important;
	font-family: $FONTFAMILY !important;
	font-style: normal !important;
	font-weight: $FONT_WEIGHT_MEDIUM !important;
	font-size: $FONT_SIZE_MEDIUM !important;
	text-align: left !important;
	&:hover {
		color: $PRIMARY_ORANGE !important;
		background: #eff4ff10 !important;
	}
	&.active {
		color: $PRIMARY_ORANGE !important;
		background: #eff4ff10 !important;
		border-left: solid 5px $PRIMARY_ORANGE !important;
	}
	margin-left: 0em !important;
}

.omis-navbar-dropdown .dropdown-item {
	background-color: transparent !important;
	border-color: transparent !important;
	font-family: $FONTFAMILY !important;
	font-style: normal !important;
	font-weight: $FONT_WEIGHT_MEDIUM !important;
	font-size: $FONT_SIZE_MEDIUM !important;
	text-align: left !important;
	width: 13.5rem;
	line-height: 0px !important;
	letter-spacing: 0.0025em !important;
	margin-top: 6px;
	margin-bottom: 6px;
	&:hover {
		color: $PRIMARY_ORANGE !important;
		background: #eb820915 !important;
	}
}
.omis-navbar-dropdown .dropdown-toggle::after {
    display: inline-block;
    margin-left: 6em;
    vertical-align: 0.2em;
    content: "";
    border-top: 0;
    border-right: 0.5em solid transparent;
    border-bottom: 0.5em solid;
    border-left: 0.5em solid transparent;
}

.omis-navbar-dropdown-help .btn-primary {
	background-color: transparent !important;
	border-color: transparent !important;
	font-family: $FONTFAMILY !important;
	font-style: normal !important;
	font-weight: $FONT_WEIGHT_MEDIUM !important;
	font-size: $FONT_SIZE_MEDIUM !important;
	text-align: left !important;

	&:hover {
		color: $PRIMARY_ORANGE !important;
		background: #eff4ff10 !important;
	}

	&.active {
		color: $PRIMARY_ORANGE !important;
		background: #eff4ff10 !important;
		border-left: solid 5px $PRIMARY_ORANGE !important;
	}

	margin-left: 0em !important;
}

.omis-navbar-dropdown-help .dropdown-item {
	background-color: transparent !important;
	border-color: transparent !important;
	font-family: $FONTFAMILY !important;
	font-style: normal !important;
	font-weight: $FONT_WEIGHT_MEDIUM !important;
	font-size: $FONT_SIZE_MEDIUM !important;
	text-align: left !important;
	width: 13.5rem;
	line-height: 0px !important;
	letter-spacing: 0.0025em !important;
	margin-top: 6px;
	margin-bottom: 6px;

	&:hover {
		color: $PRIMARY_ORANGE !important;
		background: #eb820915 !important;
	}
}

.omis-navbar-dropdown-help .dropdown-toggle::after {
	display: inline-block;
	margin-left: 6em;
	vertical-align: 0.2em;
	content: "";
	border-top: 0.5em solid;
	border-right: 0.5em solid transparent;
	border-bottom: 0;
	border-left: 0.5em solid transparent;
}

@media (min-height: 768px) {

	.omis-navbar-bottom > .omis-navbar-dropdown{
		background-color: transparent !important;
		border-color: transparent !important;
		line-height: 0px !important;
		letter-spacing: 0.0025em !important;
		border-left: solid 5px $COLOR_NAVBAR !important;
		border-radius: 0% !important;
		&:hover {
			color: $PRIMARY_ORANGE !important;
			background: #eff4ff10 !important;
			border-left: solid 5px $PRIMARY_ORANGE !important;
		}
		&.active {
			color: $PRIMARY_ORANGE !important;
			background: #eff4ff10 !important;
			border-left: solid 5px $PRIMARY_ORANGE !important;
		}
	}

	.omis-navbar-bottom > .omis-navbar-dropdown-help {
		background-color: transparent !important;
		border-color: transparent !important;
		line-height: 0px !important;
		letter-spacing: 0.0025em !important;
		border-left: solid 5px $COLOR_NAVBAR !important;
		border-radius: 0% !important;

		&:hover {
			color: $PRIMARY_ORANGE !important;
			background: #eff4ff10 !important;
			border-left: solid 5px $PRIMARY_ORANGE !important;
		}

		&.active {
			color: $PRIMARY_ORANGE !important;
			background: #eff4ff10 !important;
			border-left: solid 5px $PRIMARY_ORANGE !important;
		}
	}

	.omis-navbar-bottom > .omis-navbar-dropdown > #omis-navbar-dropdown-account > svg {
		margin-left: 13px;
		margin-right: 8px;
		margin-bottom: 4px;
	}

	.omis-navbar-bottom > .omis-navbar-dropdown > #omis-navbar-dropdown-account {
		&:hover {
			background-color: transparent !important;
		}
		height: 2em !important;
		padding-top: 0.5em !important;
		padding-bottom: 0em !important;
		align-items: center !important;
		margin-top: 0em !important;
	}

	.omis-navbar-bottom > .omis-navbar-dropdown-help > #omis-navbar-dropdown-help > svg {
		margin-left: 13px;
		margin-right: 8px;
		margin-bottom: 4px;
	}

	.omis-navbar-bottom > .omis-navbar-dropdown-help > #omis-navbar-dropdown-help {
		&:hover {
			background-color: transparent !important;
		}

		height: 2em !important;
		padding-top: 0.5em !important;
		padding-bottom: 1.525em !important;
		align-items: center !important;
		margin-top: 0em !important;
		margin-bottom: 15px;
	}

	.omis-navbar-bottom {
		display: unset !important;
		left: 0em !important;
		padding-left: 0em !important;
		margin-left: 0em !important;
		position: absolute;
		bottom: 1.5rem;
	}
}

.omis-navbar-bottom {
	display: none;
}


html {
	font-size: 14px;
}
@media (min-width: 768px) {
	html {
		font-size: 16px;
	}
}

.box-shadow {
	box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
