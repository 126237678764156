
.box {
    width: 100%;
}

.centeredDiv {
    margin-left:45%;
}

        .box .steps ul {
            overflow: hidden;
            padding-top:1%;
        }

            .box .steps ul div {
                width:20%;
            }

            .box .steps ul li div {
                color: #fff !important;
                padding: 10px 0 10px 45px;
                position: relative;
                background: #F05454;
                width: 100%;
                height: 100%;
            }

                .box .steps ul li div span {
                    font-size: 13px;
                }

            .box .steps ul li:first-child div {
                width: 100%;
                height: 100px;
                padding-left: 13%;
            }

            .box .steps ul li div::before {
                content: " ";
                border-top: 50px solid transparent;
                border-bottom: 50px solid transparent;
                border-left: 30px solid #ddd;
                position: absolute;
                top: 50%;
                margin-top: -50px;
                left: 100%;
                z-index: 1;
                margin-left: 1px;
            }

            .box .steps ul li div::after {
                content: " ";
                border-top: 50px solid transparent;
                border-bottom: 50px solid transparent;
                border-left: 30px solid #F05454;
                position: absolute;
                top: 50%;
                margin-top: -50px;
                left: 100%;
                z-index: 2;
            }

            .box .steps ul li.done div {
                border-color: #16C79A !important;
                color: #fff !important;
                background: #16C79A !important;
                padding-left: 13%;
            }

                .box .steps ul li.done div::after {
                    border-left: 30px solid #16C79A;
                }


            .box .steps ul li.gray div {
                border-color: #adb5bd !important;
                color: #fff !important;
                background: #adb5bd !important;
                padding-left: 13%;
            }

                .box .steps ul li.gray div::after {
                    border-left: 30px solid #adb5bd;
                }

            .box .steps ul li.active div {
                border-color: #16C79A !important;
                color: #fff !important;
                background: #16C79A !important;
                padding-left: 13%;
            }

                .box .steps ul li.active div::after {
                    border-left: 30px solid #16C79A;
                }

    .box .step-component {
        padding: 50px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .box .btn-component {
        padding: 20px;
        display: flex;
        justify-content: space-between;
    }
