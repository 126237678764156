text{
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #666666;
}

.addNoteDiv{
	border: none !important;
	width: 100% !important;
	height: 100% !important;
	background: #ffffff;
}

.input{
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 8px 8px 8px 12px;
	gap: 8px;
	width: 100%;
	height: 40px;
	background: #FAFAFA;
	border: 1px solid #CCCCCC;
	border-radius: 6px;
}

.btn{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	gap: 8px;
	width: 100% ;
	height: 40px;
	background: #ffffff !important;
	border-radius: 6px !important;
	border-color: #333333 !important;
}
.btn-dark{
  background: #333333 !important;
}

h3{
  
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #333333;

}  

.reportChip{
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 4px 12px 4px 8px;
	gap: 4px;
	position: relative;
	width: fit-content;
	height: 28px;
	background: #FFFFFF;

	border: 1px solid #CCCCCC;
	border-radius: 50px;
}

.reportChip-text {
  width: 40px;
  height: 20px;
  
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}

.input-textArea{
  box-sizing: border-box;
  width: 200%;
  height: 125px;
  background: #FAFAFA;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
}

.darkColor {
    color: #333333;
}

.darkColor:hover {
    color: #333333;
    text-decoration-color: #333333;
}

.omisSearchInput {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 8px;
    gap: 4px;
    width: 150px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24);
    border-radius: 12px;

}

.omisSearchInputLong {
    box-sizing: border-box !important;
    align-items: center !important;
    padding: 8px !important;
    gap: 8px !important;
    background: #FFFFFF !important;
    border: 1px solid #F0F0F0 !important;
    box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24) !important;
    border-radius: 12px !important;
}

.omisSearchDropDown {
    box-sizing: border-box !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: flex-start !important;
    padding: 10px 10px 10px 12px !important;
    gap: 4px !important;
    height: 40px !important;
    background: #FFFFFF !important;
    border: 1px solid #F0F0F0 !important;
    box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24) !important;
    border-radius: 12px !important;
}

.omisTimeBox {
    display: block;
    flex-direction: row;
    position: relative;
    width: 19.5em;
    height: 30%;
    background: #FFFFFF;
	margin-left: 2em;

	font-family: 'Urbanist' !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 24px !important;

	letter-spacing: 0.0025em !important;

	color: #48515B !important;
}


.omisFilterCard {
    display: block;
    flex-direction: row;
    position: relative;
    height: 54px;
}

.omisFilterCardButtonBG {
    box-sizing: border-box;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	background: #FFFFFF;
	border: 1px solid #F0F0F0;

	box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24);
	border-radius: 12px;

	font-family: 'Urbanist';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;

	letter-spacing: 0.0025em;

	color: #333C47;
	max-height: 2.5em !important;
}


.gasStationBox {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 4px;
    gap: 4px;
    width: 100%;
    height: 60px;
    border-right: 1px solid #CCCCCC;
}

.primaryColor{
    color:#FF7366
}

.marker-cluster-small {
    background-color: rgba(181, 226, 140, 0.6);
}

.marker-cluster-small div {
	background-color: rgba(110, 204, 57, 0.6);
}

.marker-cluster-medium {
    background-color: rgba(241, 211, 87, 0.6);
}

.marker-cluster-medium div {
	background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
    background-color: rgba(253, 156, 115, 0.6);
}

.marker-cluster-large div {
	background-color: rgba(241, 128, 23, 0.6);
}

.leaflet-oldie .marker-cluster-small {
    background-color: rgb(181, 226, 140);
}

.leaflet-oldie .marker-cluster-small div {
	background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-cluster-medium {
    background-color: rgb(241, 211, 87);
}

.leaflet-oldie .marker-cluster-medium div {
	background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-cluster-large {
    background-color: rgb(253, 156, 115);
}

.leaflet-oldie .marker-cluster-large div {
	background-color: rgb(241, 128, 23);
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
}

.marker-cluster div {
	width: 30px;
	height: 30px;
	margin-left: 5px;
	margin-top: 5px;
	text-align: center;
	border-radius: 15px;
	font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.marker-cluster span {
	line-height: 30px;
}

.succes-textStyle {
    color: #0F8B6C;
}

.danger-textStyle {
    color: #F05454;
}

.pending-textStyle {
    color: #D5892D;
}

.statusDropdown {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    width: 100%;
    height: 28px;
    border-radius: 4px;
    margin:5%;
}
.statusNew {
	background: #E8F9F5;
}

.statusInprogress {
	background: #FFF9EE;
}

.statusWorkDone {
	background: #EFF4FF;
}

.statusClosed {
	background: #FEEEEE;
}

.createReportStepTwoCardBox {
    box-sizing: border-box !important;
    display: inline-grid !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    padding: 16px 16px 24px !important;
    gap: 16px !important;
    width: 100% !important;
    height: 100% !important;
    background: #FFFFFF !important;
    border: 1px solid #F0F2F5 !important;
    box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24) !important;
    border-radius: 16px !important;
}

.MuiDataGrid-columnHeaders {
    background-color: #E0E0E0;
}

.MuiDataGrid-row.even {
    background-color: #F5F5F5;
}

.MuiDataGrid-row.even.unreadLine {
	font-weight: bold;
}

.MuiDataGrid-row.odd.unreadLine {
    font-weight: bold;
}

.MuiDialogContent-root {
    width:100%;
}

.MuiDataGrid-columnHeaderTitle-bold {
    font-weight: bold;
}

.MuiDataGrid-columnHeaders-action {
    background-color: #E0E0E0;
    /*width: 67px !important;
    max-width: 67px !important;*/
}

.MuiDataGrid-pinnedColumnHeaders {
    background-color: #E0E0E0 !important; /* rgba(235, 128, 9, 0.5) !important; */
}

.MuiDataGrid-columnHeaders-padding {
    margin-right:17px;
    width:72px;
}

.styles-module_close__2I1sI.react-simple-image-viewer__close {
    opacity: 0.6;
    color: #FF7043;
}

.styles-module_close__2I1sI.react-simple-image-viewer__close:hover {
    opacity: 1;
    color: white;
}

.styles-module_prev__KqFRp.react-simple-image-viewer__previous {
    opacity: 0.6;
    color: #FF7043;
}
    .styles-module_prev__KqFRp.react-simple-image-viewer__previous:hover {
        opacity: 1;
        color: white;
    }

.styles-module_next__1uQwZ.react-simple-image-viewer__next {
    opacity: 0.6;
    color: #FF7043;
}

.styles-module_next__1uQwZ.react-simple-image-viewer__next:hover {
	opacity: 1;
	color: white;
}