@import "../../variables";

.omis-button {
    margin-bottom: 14px !important;
    border-radius: $BORDER_RADIUS !important;
    height: 40px !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: $FONT_SIZE_LARGE !important;
    font-weight: $FONT_WEIGHT_MEDIUM !important;
    line-height: 20px !important;
    font-style: normal !important;
    text-transform: none !important;
    margin-right: $MARGIN_DEFAULT !important;
    width: 100%;
	box-sizing: border-box !important;
}

.omis-button-primary {
	padding: 8px 12px !important;
	gap: 4px !important;

	background: $PRIMARY_ORANGE !important;
	border-radius: 8px !important;

	font-family: 'Urbanist' !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 1rem !important;
	line-height: 1.3rem !important;
	

	letter-spacing: 0.01rem !important;

	color: #F4F8FF !important;
	
    &:hover {
        background-color: $COLOR_WHITE !important;
        color: $COLOR_BLACK !important;
        box-shadow: 0 1px 3px $SHADOW_COLOR;
    }
    &:disabled {
        background-color: $COLOR_DISABLED_GRAY !important;
        box-shadow: none !important;
    }
	&.Mui-disabled {
        background-color: $COLOR_DISABLED_GRAY !important;
        box-shadow: none !important;
    }
}

.omis-dropdown-menu-button {
	box-sizing: border-box !important;

	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	padding: 8px 12px 8px 8px !important;
	gap: 4px !important;

	background: #FFFFFF !important;

	border: 1px solid #F0F2F5 !important;

	box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24) !important;
	border-radius: 8px !important;

	font-family: 'Urbanist' !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 20px !important;

	letter-spacing: 0.01em !important;

	color: #333C47 !important;
}

.MuiDialogActions-root {
	.Mui-disabled {
		background-color: $COLOR_DISABLED_GRAY !important;
		box-shadow: none !important;
	}
}

.omis-button-secondary {
    border: 1px solid $COLOR_BLACK_100;
    box-shadow: $SHADOW_SMALL;
	box-sizing: border-box !important;

	padding: 8px 12px !important;
	gap: 4px !important;

	background: #FFFFFF !important;

	border: 1px solid #F0F2F5 !important;

	box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24) !important;
	border-radius: 12px !important;

	font-family: 'Urbanist' !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 1rem !important;
	line-height: 1.3rem !important;

	letter-spacing: 0.01rem !important;

	color: #677A8E !important;

    &:hover {
        background-color: $COLOR_WHITE !important;
        border: 1px solid $PRIMARY_ORANGE !important;
        box-shadow: none !important;
    }
    &:disabled {
        background-color: $COLOR_DISABLED_GRAY !important;
        box-shadow: none !important;
    }
	&.Mui-disabled {
        background-color: $COLOR_DISABLED_GRAY !important;
        box-shadow: none !important;
    }
}

.omis-button-primary-start-icon {
	box-sizing: border-box !important;
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	padding: 8px 12px 8px 8px !important;
	gap: 4px !important;
	background: #FFFFFF !important;
	border: 1px solid #F0F2F5 !important;
	box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24) !important;
	border-radius: 8px !important;

	font-family: 'Urbanist' !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 20px !important;
	letter-spacing: 0.01em !important;
	color: #333C47 !important;

	.icon {
		background: #677A8E !important;
	}

	&:disabled {
        background-color: $COLOR_BLACK_200 !important;
        box-shadow: none !important;
    }
	&.Mui-disabled {
        background-color: $COLOR_BLACK_200 !important;
        box-shadow: none !important;
    }

}

.omis-button-icon {
	box-sizing: border-box !important;
	align-items: center !important;
	padding: 8px !important;
	gap: 4px !important;

	background: #FFFFFF !important;

	border: 1px solid #F0F2F5 !important;

	box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24) !important;
	border-radius: 8px !important;
	margin-left: 1em !important;

	&:disabled {
        background-color: $COLOR_BLACK_200 !important;
        box-shadow: none !important;
    }
	&.Mui-disabled {
        background-color: $COLOR_BLACK_200 !important;
        box-shadow: none !important;
    }
}

.omis-button-filter-badgeBox {
    box-sizing: border-box !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 8px !important;
    gap: 4px !important;
    position: absolute !important;
    left: 96.1% !important;
    right: 2.6% !important;
    top: 58.82% !important;
    bottom: 29.41% !important;
    background: #E8F9F5 !important;

    border: 0.5px solid #0F8B6C !important;
    border-radius: 8px !important;
}

.omis-button-filter-badgeContent {
    width: 7px !important;
    height: 24px !important;
    font-family: 'Urbanist' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    display: flex !important;
    align-items: center !important;
    letter-spacing: 0.0025em !important;
    color: #0F8B6C !important;
}

#div-file-upload {
	height: 10rem;
	max-width: 100%;
	text-align: center;
	position: relative;
}

#input-file-upload {
	display: none;
}

#label-file-upload {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-width: 2px;
	border-radius: 1rem;
	border-style: dashed;
	border-color: #cbd5e1;
	background-color: #f8fafc;
}

.documentUploadDiv {
	height: 10rem;
	max-width: 100%;
	text-align: center;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border-width: 2px;
	border-radius: 1rem;
	border-style: dashed;
	border-color: #cbd5e1;
	background-color: #f8fafc;
}

.upload-button {
	cursor: pointer;
	padding: 0.25rem;
	font-size: 1rem;
	border: none;
	font-family: 'Urbanist' !important;
	background-color: transparent;
}

.upload-button:hover {
	text-decoration-line: underline;
}