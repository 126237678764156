root {
    background-color: white;
    width: 50040px;
    display: "flex";
    justify-content:center;
    align-items: flex-end;
}

icon {
    margin: 40px;
}

iconHover {
    margin:40px;
}

iconHover:hover {
    color: red
}

cardHeader {
    text-align: center; align-items: center; background-color: white;
}

input {
    display: "none";
}

title {
    color: deepskyblue; font-weight: bold; font-family: "Montserrat"; align-items: center
}

button {
    color: blue;
    margin: 10px;
}

secondaryButton {
    color: gray;
    margin: 10px;
}
