@import "variables";

a {
  color: $COLOR_INFO;
}

.row {
  width: 100%;
}
.heightTestRoot {
	height: 100% !important;
	overflow: scroll !important;
	min-height: 100vh !important;
}

h1 {
  font-family: $FONTFAMILY;
  font-style: normal;
  font-weight: $FONT_WEIGHT_REGULAR;
  font-size: $FONT_SIZE_H1;
  line-height: 40px;
  letter-spacing: 0.0025em;
  color: $COLOR_BLACK_800;
}

h2 {
  font-family: $FONTFAMILY;
  font-style: normal;
  font-weight: $FONT_WEIGHT_BOLD;
  font-size: $FONT_SIZE_H2;
  line-height: 36px;
  letter-spacing: 0.0025em;
  color: $COLOR_BLACK_900;
}

h3 {
  font-family: $FONTFAMILY;
  font-style: normal;
  font-weight: $FONT_WEIGHT_BOLD;
  font-size: $FONT_SIZE_H3;
  line-height: 32px;
  color: $COLOR_BLACK_800;
  text-align: left;
}

.omis-dialog-title {
  font-weight: $FONT_WEIGHT_BOLD !important;
  font-size: $FONT_SIZE_H3 !important;
  line-height: 32px !important;
  color: $COLOR_BLACK_900 !important;
}

.omis-dialog {
  box-shadow: $SHADOW_LARGE !important;
  border-radius: $BORDER_RADIUS_BIG !important;
}

.shadowSmall {
  box-shadow: $SHADOW_SMALL;
}

.shadowMiddle {
  box-shadow: $SHADOW_MIDDLE;
}

.shadowBig {
  box-shadow: $SHADOW_LARGE;
}

html {
	height: 100%;
}

body {
  font-family: $FONTFAMILY;
  background: $COLOR_BACKGROUND !important;
  height: 100% !important;
}

.omis-box {
  box-shadow: $SHADOW_MIDDLE;
  border-radius: $BORDER_RADIUS_BIG;
  background: $COLOR_WHITE;
  padding: $PADDING_DEFAULT;
  margin-top: $MARGIN_DEFAULT;
  margin-bottom: $MARGIN_DEFAULT;
  width:100%;
}

.omis-notification-row .omis-project-row{
  margin-bottom: $MARGIN_DEFAULT;
}

.omis-notification-row time {
  float: right;
}

.userChip {
	font-family: 'Urbanist' !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 15px !important;
	line-height: 24px !important;
	
	letter-spacing: 0.0025em !important;
	
	color: #1B51B3 !important;

	box-sizing: border-box !important;

	align-items: center !important;
	gap: 4px !important;

	background: #FFFFFF !important;

	border: 1px solid #F0F2F5 !important;
	border-radius: 8px !important;

}

label {
  font-family: $FONTFAMILY;
  font-style: normal;
  font-weight: $FONT_WEIGHT_REGULAR;
  font-size: $FONT_SIZE_MEDIUM;
  line-height: 24px;
  letter-spacing: 0.0025em;
  color: #5A6672;
}

.hint {
  font-family: $FONTFAMILY;
  font-style: normal;
  font-weight: $FONT_WEIGHT_REGULAR;
  font-size: $FONT_SIZE_SMALL;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: $COLOR_BLACK_800;
}

caption.regular {
  font-family: $FONTFAMILY;
  font-style: normal;
  font-weight: $FONT_WEIGHT_REGULAR;
  font-size: $FONT_SIZE_SMALL;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $COLOR_BLACK_800;
}

caption.bold {
  font-family: $FONTFAMILY;
  font-style: normal;
  font-weight: $FONT_WEIGHT_BOLD;
  font-size: $FONT_SIZE_SMALL;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $COLOR_BLACK_800;
}

p {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

p.regular {
  font-family: $FONTFAMILY;
  font-style: normal;
  font-weight: $FONT_WEIGHT_REGULAR;
  font-size: $FONT_SIZE_MEDIUM;
  line-height: 24px;
  letter-spacing: 0.0025em;
  color: $COLOR_BLACK_800;
}

p.medium {
  font-family: $FONTFAMILY;
  font-style: normal;
  font-weight: $FONT_WEIGHT_MEDIUM;
  font-size: $FONT_SIZE_MEDIUM;
  line-height: 24px;
  letter-spacing: 0.0025em;
  color: $COLOR_BLACK_800;
}

p.bold {
  font-family: $FONTFAMILY;
  font-style: normal;
  font-weight: $FONT_WEIGHT_BOLD;
  font-size: $FONT_SIZE_MEDIUM;
  line-height: 24px;
  letter-spacing: 0.0025em;
  color: $COLOR_BLACK_800;
}

sub.regular {
  font-family: $FONTFAMILY;
  font-style: normal;
  font-weight: $FONT_WEIGHT_REGULAR;
  font-size: $FONT_SIZE_LARGE;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: $COLOR_BLACK_800;
}

sub.medium {
  font-family: $FONTFAMILY;
  font-style: normal;
  font-weight: $FONT_WEIGHT_MEDIUM;
  font-size: $FONT_SIZE_LARGE;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: $COLOR_BLACK_800;
}

.omis-delete-menuitem {
  color: $COLOR_ERROR !important;
}

.omis-avatar-small {
	box-sizing: border-box !important;
	background-color: $PRIMARY_ORANGE !important;

	border: 0.7px solid #FAFBFE !important;
	border-radius: 8px !important;
}
/***************************************************************/
//---------------[OMIS LOGIN PAGE]-----------------
//---------------[Login Left Side]-----------------

//Hintergrund
.loginLeft {
	background-color: $COLOR_WHITE;
	box-sizing: border-box;
	background: rgb(208,208,208);
	background: linear-gradient(162deg, rgba(208,208,208,1) 0%, rgba(225,221,220,1) 100%);
	box-shadow: 4px 0px 12px rgba(164, 170, 176, 0.24);
	min-height: calc(100vh - 120px);
}

//Header
.loginLeftHeader {
	background: #FFFFFF;
	height: 120px;
	box-shadow: 4px 0px 12px rgba(164, 170, 176, 0.24);
}

//Header Text & Logo
.loginLeftHeaderItems {
	margin-top: 26px;
	margin-left: 1rem;
	font-family: 'Urbanist';
	font-style: normal;
	font-weight: 600;
	font-size: 48px;
	line-height: 58px;
	letter-spacing: 0.01em;
	color: $COLOR_BLACK;
}

//Inhalt Linke Box
.loginLeftText {
	margin-left: 2em;
	margin-top: 10em;

	h2 {
		font-family: 'Urbanist';
		font-style: normal;
		font-weight: 600;
		font-size: 32px;
		line-height: 40px;
		letter-spacing: 0.0025em;
		color: #59575a;
	}

	p {
		font-family: 'Urbanist';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: 0.01em;
		color: #59575a;
	}
}
//Linke Box grosses Logo
.loginLeftBackgroundLogo {
	margin-left: 1.8em;
	//filter: invert(32%) sepia(12%) saturate(0%) hue-rotate(62deg) brightness(83%) contrast(90%);
}
//---------------[Login Left Side ENDE]-----------------
//---------------[Login Right Side]-----------------
.loginRightHeader {
	h1 {
		font-family: 'Urbanist';
		font-style: normal;
		font-weight: 600;
		font-size: 28px;
		line-height: 36px;
		text-align: center;
		letter-spacing: 0.0025em;
		color: $PRIMARY_ORANGE;
	}

	h2 {
		font-family: 'Urbanist';
		font-style: normal;
		font-weight: 600;
		font-size: 28px;
		line-height: 36px;
		text-align: center;
		letter-spacing: 0.0025em;
	}
}

.loginInput {
	MuiTextField {
		styleOverrides {
			root {
				min-height: 3.5em !important;
			}
		}
	}
}

.loginBackground {
	background: var(--background-light, #FFF) !important;
	width: 100vw !important;
	min-height: 100vh !important;
}
//---------------[Login Right Side ENDE]-----------------

//---------------[OMIS LOGIN PAGE ENDE]-----------------
/***************************************************************/


.CardText {

	font-family: 'Urbanist';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;

	letter-spacing: 0.01em;
	margin-left: 1.5em !important;
	margin-bottom: 0.5em !important;

	color: #48515B;
}
.topSpacingContent {
	//margin-top: 2em !important;
}
/***************************************************************/
//Profile Details Start

.paddingTop {
	margin-top: 2em !important;
}

.userProfilCard {
	background-color: #FFFFFF !important;
	padding: 16px;
	gap: 16px;

	border-radius: 24px;

	border: none !important;
}

.userProfilCardText {

	font-family: 'Urbanist';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;

	letter-spacing: 0.01em;

	color: #48515B;
}

.userProfilCardContentText {

	font-family: 'Urbanist' !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 1em !important;
	line-height: 24px !important;

	letter-spacing: 0.0025em !important;

	color: #48515B !important;

}
.userProfilCardAvatar {
	box-sizing: border-box;
	border: 2px solid #F0F2F5;
	border-radius: 8px !important;
}

.userProfilCardContentText2 {
	font-family: 'Urbanist' !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 12px !important;
	line-height: 16px !important;

	letter-spacing: 0.02em !important;
	text-transform: uppercase !important;

	color: #48515B !important;
}

.userProfilCardContentText3 {
	font-family: 'Urbanist' !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 16px !important;
	line-height: 24px !important;

	letter-spacing: 0.01em !important;

	color: #48515B !important;
}

.userProfilCardContentText4 {
	font-family: 'Urbanist' !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 16px !important;
	line-height: 20px !important;

	letter-spacing: 0.01em !important;

	color: #171E27 !important;
}

.userProfilCardContentText5 {
	font-family: 'Urbanist' !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 24px !important;

	letter-spacing: 0.0025em !important;

	color: #171E27 !important;
}

.userProfilCardContentText6 {
	font-family: 'Urbanist' !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 24px !important;

	letter-spacing: 0.0025em !important;

	color: #171E27 !important;
}

.userProfilCardContentText7 {
	font-family: 'Urbanist' !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 24px !important;

	letter-spacing: 0.0025em !important;

	color: #48515B !important;
}

.userProfilCardContentTextWB {
	box-sizing: border-box !important;
	padding: 10px 10px 10px 12px !important;
	gap: 4px !important;
	background: #FFFFFF !important;
	border-radius: 12px !important;
	position: relative;
	min-height:48px !important;
}

.userProfilCardContentTextWB::before {
	content: "";
	position: absolute;
	top: 1px;
	right: 4px;
	bottom: 1px;
	left: 4px;
	border: 1px solid #F0F2F5 !important;
	border-radius: 11px; /* Adjusted for 1px inward border */
	pointer-events: none;
}

.userProfilCardContentTextWB1 {
	font-family: 'Urbanist' !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 24px !important;
	letter-spacing: 0.0025em !important;
	color: #171E27 !important;

	box-sizing: border-box !important;
	padding: 10px 10px 10px 12px !important;
	gap: 4px !important;

	background: #FFFFFF !important;

	border: 1px solid #F0F2F5 !important;
	border-radius: 12px !important;
}



.userProfilCardInfoText {
	font-family: 'Urbanist' !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 24px !important;
	letter-spacing: 0.0025em !important;
	color: #2773FF !important;
}

.userProfilCardCollapse {
	box-sizing: border-box;
	background: #FFFFFF;
	border: 1px solid #F0F2F5;
	box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24);
	border-radius: 8px;

	background: #92A1AF;

}

//Profile Details ENDE
/****************************************************/
/***************************************************************/
//Faultreports Start

//Faultreport Mainpage
.faultReportMain {

	max-width: 100vw !important;

	background: #FFFFFF;

	box-shadow: 0px 4px 12px rgba(103, 122, 142, 0.24);
	border-radius: 16px;
	border: none !important;


	
}

//Searchbars


//Create Faultreport

//Edit FaultReport

//ReportPopMenu


.reportChip {
	box-sizing: border-box !important;


	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	gap: 4px !important;

	background: #FFFFFF !important;
	border: 1px solid #EAEEF1 !important;
	border-radius: 8px !important;

	flex: none !important;
	order: 1 !important;
	flex-grow: 0 !important;

	//Text
	font-family: 'Urbanist' !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 24px !important;

	text-align: center !important;
	letter-spacing: 0.0025em !important;

	color: #333C47 !important;
}
.reportChipsvg {
	height: 1.5em !important;
	color: #677A8E !important;
}

.objectChip {
	box-sizing: border-box !important;

	justify-content: center !important;
	align-items: center !important;
	gap: 4px !important;

	background: #FFFFFF !important;
	border: 1px solid #EAEEF1 !important;
	border-radius: 8px !important;

	flex: none !important;
	order: 1 !important;
	flex-grow: 0 !important;

	//Text
	font-family: 'Urbanist' !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 24px !important;

	text-align: center !important;
	letter-spacing: 0.0025em !important;

	color: #333C47 !important;
}
.objectChipsvg {
	height: 1.5em !important;
	color: #677A8E !important;
}

//Faultreports ENDE
/****************************************************/

//MIUI Dialog Boxen
.MuiDialog-paper {
	display: flex !important;
	flex-direction: column !important;
	align-items: flex-start !important;
	padding: 24px !important;
	gap: 24px !important;

	position: absolute !important;

	background: #FFFFFF !important;

	box-shadow: 0px 4px 12px rgba(103, 122, 142, 0.24)  !important;
	border-radius: 12px !important;

}

.omis-main-container {
	margin-left: 16em !important;
}

.omis-header-box {
	
	margin-bottom: 2em !important;
	background-color: #FFFFFF !important;
	height: 8em !important;
	padding-top: 1em !important;
	margin-bottom: 1em !important;
	border-radius: 16px;

	box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24) !important;
	.omis-header-title {
		margin-left: 1em;
		margin-top: 1em;

		font-family: 'Urbanist';
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;

		letter-spacing: 0.01em;

		color: #1E1A26;

	}
	.omis-header-title-details {
		font-family: 'Urbanist' !important;
		font-style: normal !important;
		font-weight: 600 !important;
		font-size: 24px !important;
		line-height: 32px !important;
		letter-spacing: 0.01em !important;
		color: #1E1A26 !important;
	}

	.omis-header-buttons {
		position: relative !important;
		top: -3em !important;
		right: 1.5em !important;
	}

	.omis-header-buttons-details {
		position: relative !important;
		top: -3em !important;
		right: 0em !important;
	}
}

//*************FaultReportCreate****************//

//FaultReportCreateDetails
.faultReportCreateDetailsBox {
	padding: 16px 16px 24px 16px !important;
	gap: 16px !important;
	border-radius: 16px !important;
	border: 1px solid var(--colour-grayscale-black-100, #F0F2F5) !important;
	background: var(--colour-grayscale-white-0, #FFF) !important;
}

//FaultCatalogueCard

.createReportCatalogueCard {
	display: flex !important;
	padding: 16px !important;
	flex-direction: column !important;
	justify-content: center !important;
	align-items: flex-start !important;
	gap: 4px !important;
	border-radius: 8px !important;
	border: 1px solid var(--colour-grayscale-bg, #FAFBFE) !important;
	background: var(--colour-grayscale-white-0, #FFF) !important;
	box-shadow: 0px 1px 3px 0px rgba(103, 122, 142, 0.24) !important;
}

.createReportCatalogueCardDisabled {
	display: flex !important;
	padding: 16px !important;
	flex-direction: column !important;
	justify-content: center !important;
	align-items: flex-start !important;
	gap: 4px !important;
	border-radius: 8px !important;
	border: 1px solid var(--colour-grayscale-bg, #FAFBFE) !important;
	background: var(--colour-grayscale-bg, #FAFBFE) !important;
}

.createReportCatalogueCardActive {
	display: flex !important;
	padding: 16px !important;
	flex-direction: column !important;
	justify-content: center !important;
	align-items: flex-start !important;
	gap: 4px !important;
	border-radius: 8px;
	border: 1px solid var(--colour-primary-middle, #FFA98E) !important;
	background: var(--colour-grayscale-white-0, #FFF) !important;
	box-shadow: 0px 1px 3px 0px rgba(103, 122, 142, 0.24) !important;
}

//*************Users****************//

//FaultReportCreateDetails


//UsersGroupCards
.usersGroupSearchCard {
	min-height: 6em !important;
	padding: 24px !important;
	gap: 16px !important;
	border-radius: 16px !important;
	border: 1px solid var(--colour-grayscale-black-100, #F0F2F5) !important;
	background: var(--colour-grayscale-white-0, #FFF) !important;
	box-shadow: 0px 1px 3px 0px rgba(103, 122, 142, 0.24) !important;
	color: var(--colour-grayscale-black-900, #171E27) !important;

}

.usersGroupCards {
	min-height: 4em !important;
	padding: 16px !important;
	gap: 8px !important;
	border-radius: 16px !important;
	border: 1px solid var(--colour-grayscale-black-100, #F0F2F5) !important;
	background: var(--colour-grayscale-white-0, #FFF) !important;
	box-shadow: 0px 1px 3px 0px rgba(103, 122, 142, 0.24) !important;
	color: var(--colour-grayscale-black-900, #171E27) !important;
	font-family: Urbanist !important;
	font-size: 16px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 20px !important; /* 125% */
	letter-spacing: 0.16px !important;
}

.usersGroupCreateCard {
	padding: 16px 16px 40px 16px !important;
	gap: 24px !important;
	border-radius: 24px !important;
	background: var(--colour-grayscale-white-0, #FFF) !important;
}

.usersAddUserUpload {
	width: 100% !important;
	height: 100% !important;
	border-radius: 12px !important;
	border: 1px dashed var(--colour-grayscale-black-200, #AFB8C0) !important;

}

.usersAddUserUploadText {
	margin-top: 1em !important;
	color: var(--colour-grayscale-black-600, #5A6672) !important;
	text-align: center !important;
	/* Typography/Paragraphs  + subtitle/P - Regular */
	font-family: Urbanist !important;
	font-size: 20px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 24px !important; /* 171.429% */
	letter-spacing: 0.035px !important;
}

.roleTag {
	padding: 0px 8px !important;
	gap: 4px !important;
	border-radius: 8px !important;
	border: 1px solid var(--colour-grayscale-black-100, #F0F2F5) !important;
	background: var(--colour-grayscale-bg, #FAFBFE) !important;
	color: var(--colour-grayscale-black-900, #171E27) !important;
	font-family: Urbanist !important;
	font-size: 15px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 24px !important; /* 171.429% */
	letter-spacing: 0.035px !important;
}



.font-orange {
	color: $PRIMARY_ORANGE;
}

.center {
  text-align: center;
}

.smallmap {
  height: 180px;
}

.bigmap {
	height: 800px;
}

.text-right {
  text-align: right;
}

.omis-logo-text {
	position: absolute;
	width: 68px;
	height: 38px;
	left: 40px;
	top: 0px;

	font-family: 'Urbanist';
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 38px;
	letter-spacing: 0.01em;

	/* Colour/Grayscale/White 0 */

	color: #FFFFFF;

}

.detailsCardTitle{
	margin-left:1%;
}

.stickyBar {
	position: -webkit-sticky; /* Safari */
	position: sticky;
	padding: 20px;
	background: #FFFFFF !important;
	border-radius: 24px;
	border: none !important;
	top: 0;
	z-index: 2;
}

//Filter Style
.css-1v3bzhq-MuiFormControl-root-MuiTextField-root {
    border: none !important;
    box-shadow: none !important;
    border-radius: 0px !important;
}

.rowContainer {
	width: 100%;
	padding-right: 0px !important;
	padding-left: 0px !important;
	margin-right: auto;
	margin-left: auto;
}

.disabledField {
	background-color: #F0F2F5 !important;
}

.myDatePicker fieldset.MuiOutlinedInput-notchedOutline {
	border-style:hidden;
}

.myDatePicker .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
	border-style: hidden;
}