.omis-checkbox, .omis-slider, .omis-textbox, .omis-select {
	margin-bottom: 15px !important;
	margin-left: 0.8rem;
	flex: none !important;
	flex-grow: 0 !important;
}

.has-icon .form-control {
    padding-left: 2.375rem;
}

.has-icon .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.omis-textbox {

	box-sizing: border-box !important;
	display: flex !important;
	flex-direction: column !important;
	justify-content: center !important;
	align-items: flex-start !important;
	padding: 10px 10px 10px 12px !important;
	gap: 4px !important;


	background: #FFFFFF !important;
	border: 1px solid #F0F2F5 !important;
	box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24) !important;
	border-radius: 12px !important;

	flex: none !important;
	order: 1 !important;
	align-self: stretch !important;
	flex-grow: 0 !important;

	font-family: 'Urbanist' !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 24px !important;

	letter-spacing: 0.0025em !important;

	color: #171E27 !important;

	.MuiInput-underline:before {
		border: none !important;
	}

	.MuiInput-underline:hover {
		border: none !important;
	}

	.MuiInput-underline:focus {
		border: none !important;
	}

	.MuiInput-underline:active {
		border: none !important;
	}

}

.omis-datetimepicker {
	box-sizing: border-box !important;
	background: #FFFFFF !important;
	border: 1px solid #F0F2F5 !important;
	border-radius: 12px !important;

	font-family: 'Urbanist' !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 24px !important;

	letter-spacing: 0.0025em !important;

	color: #5A6672 !important;

	&:disabled {
		background: #EEF1F4 !important;
	}

}

.omis-select {
	box-sizing: border-box !important;
	padding: 8px 10px 8px 8px !important;
	gap: 4px !important;
	background: #FFFFFF !important;
	border: 1px solid #F0F2F5 !important;
	box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24) !important;
	border-radius: 12px !important;

	.MuiInput-underline:before {
		border: none !important;
	}

	.MuiInput-underline:hover {
		border: none !important;
	}

	.MuiInput-underline:focus {
		border: none !important;
	}

	.MuiInput-underline:active {
		border: none !important;
	}
	
}

.loginInputs {
	.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
		box-sizing: border-box !important;
		display: flex !important;
		flex-direction: column !important;
		justify-content: center !important;
		align-items: flex-start !important;
		padding: 10px 10px 10px 12px !important;
		gap: 4px !important;
		background: #FFFFFF !important;
		border: 1px solid #F0F2F5 !important;
		box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24) !important;
		border-radius: 16px !important;

		flex: none !important;
		order: 1 !important;
		align-self: stretch !important;
		flex-grow: 0 !important;

		max-width: 100vw;
		min-width: 20vw;

		.MuiInput-underline:before {
			border: none;
		}
	}
	.css-1qmnz0q-MuiFormLabel-root-MuiInputLabel-root {
		width: 114px !important;
		height: 24px !important;
	
		font-family: 'Urbanist' !important;
		font-style: normal !important;
		font-weight: 600 !important;
		font-size: 14px !important;
		line-height: 24px !important;
	
		letter-spacing: 0.0025em !important;
	
		color: #000000 !important;
		margin-bottom: 1em;
	}
	
}

.loginSubmit {
	.css-l3yew9-MuiButtonBase-root-MuiButton-root {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 8px 12px;
		gap: 4px;

		max-width: 100vw;
		height: 40px;

		background: #FF7043;
		border-radius: 12px;
	}
	
}

.MuiInputBase-adornedEnd {
	//min-height: 2.5em !important;
	//max-height: 2.5em !important;
}

.MuiFormControlLabel-labelPlacementEnd {
	min-height: 1em !important;
	max-height: 2em !important;
	max-width: fit-content;
}

.OmisComponent{
	height:47px;
}

.omisInputLabels {
	color: var(--colour-grayscale-black-900, #171E27) !important;
	font-family: Urbanist !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 600 !important;
	line-height: 24px !important;
	letter-spacing: 0.035px !important;
	margin-bottom: 0.5rem !important;
}