$FONTFAMILY: "Urbanist"; //TODO: add backup fontfamilies

$FONT_SIZE_SMALL: 12px;
$FONT_SIZE_MEDIUM: 14px;
$FONT_SIZE_LARGE: 16px;
$FONT_SIZE_H3: 24px;
$FONT_SIZE_H2: 28px;
$FONT_SIZE_H1: 32px;

$FONT_WEIGHT_REGULAR: 400;
$FONT_WEIGHT_MEDIUM: 500;
$FONT_WEIGHT_BOLD: 600;

$COLOR_BLACK: #000000;
$COLOR_BLACK_900: #171E27;
$COLOR_BLACK_800: #333C47;
$COLOR_BLACK_700: #48515B;
$COLOR_BLACK_600: #5A6672;
$COLOR_BLACK_500: #677A8E;
$COLOR_BLACK_400: #7F8F9F;
$COLOR_BLACK_300: #94A3B2;
$COLOR_BLACK_200: #AFB8C0;
$COLOR_BLACK_100: #F0F2F5;

$COLOR_DISABLED_GRAY: #AFB8C0;
$COLOR_TEXT_GRAY: #48515B;

$SHADOW_COLOR: rgba(103, 122, 142, 0.24);
$SHADOW_SMALL: 0 1px 3px $SHADOW_COLOR;
$SHADOW_MIDDLE: 0 2px 6px $SHADOW_COLOR;
$SHADOW_LARGE: 0 4px 12px $SHADOW_COLOR;

$COLOR_NAVBAR: #171E27;

$BORDER_RADIUS: 8px;
$BORDER_RADIUS_BIG: 16px;

$PADDING_DEFAULT: 15px;
$MARGIN_DEFAULT: 15px;

$PRIMARY_ORANGE: #F39200;
//$PRIMARY_ORANGE_DARK: rgba(255, 112, 67, 0.9); //not based on omis orange
//$PRIMARY_ORANGE_MEDIUM: #FFA98E; //not based on omis orange
//$PRIMARY_ORANGE_LIGHT: #FFF1EC; //not based on omis orange

$SECONDARY_BLUE: #517DDA;
$SECONDARY_BLUE_DARK: #2655B9;
$SECONDARY_BLUE_MEDIUM: #2B5ECA;
$SECONDARY_BLUE_LIGHT: #EFF4FF;

$COLOR_WHITE: #FFFFFF;

$COLOR_SUCCESS: #16C79A;
$COLOR_SUCCESS_DARK: #0F8B6C;
$COLOR_SUCCESS_MEDIUM: #5CD8B8;
$COLOR_SUCCESS_LIGHT: #E8F9F5;

$COLOR_ERROR: #F05454;
$COLOR_ERROR_DARK: #A83B3B;
$COLOR_ERROR_MEDIUM: #F58787;
$COLOR_ERROR_LIGHT: #FEEEEE;

$COLOR_WARNING: #FFC453;
$COLOR_WARNING_DARK: #DE980F;
$COLOR_WARNING_MEDIUM: #FFD687;
$COLOR_WARNING_LIGHT: #FFF9EE;

$COLOR_INFO: #2773FF;
$COLOR_INFO__DARK: #1B51B3;
$COLOR_INFO_MEDIUM: #689DFF;
$COLOR_INFO_LIGHT: #F8FAFF;

$COLOR_TURQUOISE: #45C2D9;
$COLOR_ORANGY: #F8955D;
$COLOR_YELLOW: #F8C100;
$COLOR_VIOLET: #8D73EF;

$COLOR_TEXT_LIGHT: #F4F8FF;

$COLOR_BACKGROUND: #F0F2F5;
$COLOR_BACKGROUND_TEST: #FFC453;

:export {
    bodybackground: $COLOR_BACKGROUND;
    testbodybackground: $COLOR_BACKGROUND_TEST;
}